import React from 'react'
import Collapsible from 'react-collapsible'
import {isAdmin} from './Authorization.js'
import * as Format from './Format.js'
import {strings} from './Localization.js'
import './Fahrten.css'

export function Fahrten(props) {
  const now = props.currentDateTime
  const buchungen = props.buchungen.sort((a, b) => [a.start, a.ende, a.id] < [b.start, b.ende, b.id] ? -1 : 1)
  const laufendeBuchungen = buchungen.filter(buchung =>
      new Date(buchung.start) < now
      && new Date(buchung.ende) > now
      && buchung.beendet === "")
  const zukünftigeBuchungen = buchungen.filter(buchung =>
      new Date(buchung.start) > now
      && buchung.beendet === "")
  const abgeschlosseneBuchungen = buchungen.reverse().filter(buchung =>
      new Date(buchung.ende) < now
      || buchung.beendet !== "")
  return (
      <div className="buchungen">
        <h2>{strings.fahrtenLaufendeBuchungen}</h2>
        { laufendeBuchungen.map((buchung) => renderBuchung(props, buchung, strings.fahrtenBeenden)) }
        { laufendeBuchungen.length === 0 && <div>Keine Buchungen</div> }
        <h2>{strings.fahrtenZukünftigeBuchungen}</h2>
        { zukünftigeBuchungen.map((buchung) => renderBuchung(props, buchung, strings.fahrtenStornieren)) }
        { zukünftigeBuchungen.length === 0 && <div>Keine Buchungen</div> }
        <Collapsible
          className="abgeschlossene-buchungen"
          openedClassName="abgeschlossene-buchungen"
          lazyRender="true"
          trigger={strings.fahrtenAbgeschlosseneBuchungen}>
          { abgeschlosseneBuchungen.map((buchung) => renderBuchung(props, buchung, null)) }
          { abgeschlosseneBuchungen.length === 0 && <div>Keine Buchungen</div> }
        </Collapsible>
      </div>)
}

function renderBuchung(props, buchung, buttonName) {
  const fahrzeugName = props.fahrzeuge.find(fahrzeug => fahrzeug.id === buchung.fahrzeugId)?.name
  const writeAccess = (buchung.username === props.username || isAdmin())
  return (
      <div className="buchung" key={buchung.id}>
        <div className="horizontal-layout">
          <span className="buchungs-fahrzeug-item"><b>{fahrzeugName}</b></span>
          <span className="buchungs-fahrer-item">{buchung.username}</span>
        </div>
        <div className="vertical-layout">
          <span className="buchungs-datum-item">{Format.date(new Date(buchung.start))}</span>
          <span className="buchungs-datum-item">{Format.endDate(new Date(buchung.ende))}</span>
        </div>
        <div className="buchungs-button-item">
          {buttonName && writeAccess && <button
            type="button"
            onClick={() => props.stornierenClicked(buchung.id)}>
            {buttonName}
          </button>}
          {!buttonName && buchung.beendet && <span>
            {new Date(buchung.beendet) > new Date(buchung.start)
              ? strings.fahrtenBeendet
              : strings.fahrtenStorniert}
          </span>}
        </div>
      </div>)
}
